// /* You can add global styles to this file, and also import other style files */

// /* === Plugin styles === */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./assets/styles/_variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/fonts";
@import "./assets/styles/functions";

// /* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

// /* === Template mixins === */
@import "./assets/styles/mixins/animation";
@import "./assets/styles/mixins/badges";
@import "./assets/styles/mixins/buttons";
@import "./assets/styles/mixins/misc";
@import "./assets/styles/mixins/color-functions";
@import "./assets/styles/mixins/cards";
@import "./assets/styles/mixins/blockqoute";

// /* === Core Styles === */
@import "./assets/styles/typography";
@import "./assets/styles/reset";
@import "./assets/styles/responsive";
@import "./assets/styles/misc";
@import "./assets/styles/utilities";
@import "./assets/styles/demo";
@import "./assets/styles/spinner";
@import "./assets/styles/dashboard";

// /* === Components === */

@import "./assets/styles/components/checkbox-radio";
@import "./assets/styles/components/forms";
@import "./assets/styles/components/icons";
@import "./assets/styles/components/tables";
@import "./assets/styles/components/buttons";
@import "./assets/styles/components/breadcrumbs";
@import "./assets/styles/components/badges";
@import "./assets/styles/components/cards";
@import "./assets/styles/components/preview";
@import "./assets/styles/components/lists";
@import "./assets/styles/components/todo-list";
@import "./assets/styles/components/dropdowns";
@import "./assets/styles/components/loaders/loaders";

@import "./assets/styles/components/landing-screens/auth";

// /* === Plugin Overrides === */
@import "./assets/styles/components/plugin-overrides/slick-carousel";
@import "./assets/styles/components/plugin-overrides/jquery-jvectormap";

// /* === Layout === */
@import "./assets/styles/navbar";
@import "./assets/styles/sidebar";
@import "./assets/styles/footer";
@import "./assets/styles/layout";
